import React, {Component} from 'react';

import './LeftMenu.scss';
import Icons from "../Icons/Icons";

class LeftMenu extends Component {
    state = {
        isVisible: this.props.isVisible
    }

    render() {
        if (this.props.isVisible) {
            return (
                <div>
                    <ul className="left-menu slide-in-top visible">
                        {this.props.links}
                        <Icons tLink="https://t.me/mini_football_moscow" vLink="https://vk.com/minifootballuzao" wLink="https://wa.me/79037552612"/>
                    </ul>

                </div>
            );
        }

    }
}

export default LeftMenu;