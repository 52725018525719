import React, { Component } from 'react'

import Navigation from './Navigation/Navigation'
import Icons from './Icons/Icons'

import './Header.scss'

class Header extends Component {
    state = {
        links: [
            { title: 'Играть в зале', href: '#futsal' },
            { title: 'Играть на траве', href: '#football' },
            { title: 'Важное', href: '#important' },
        ],
    }

    render() {
        return (
            <header className="container-fluid">
                <div className="row top-header">
                    <img className="col-2 img-fluid" src={this.props.logoSrc} alt="СОЮЗ"/>
                    <div className="col-4 text-header">
                        <a className="pageTitle" href="https://поиграть.мини-футбол.москва">{this.props.pageTitle}</a>
                        <span className="d-none d-sm-none d-md-block slogan">
                            {this.props.slogan}
                        </span>
                    </div>
                    <div className="d-none d-sm-none d-md-block col-6 col-md-5 col-lg-6 icons">
                        <Icons tLink="https://t.me/mini_football_moscow" vLink="https://vk.com/minifootballuzao" wLink="https://wa.me/79037552612"/>
                    </div>
                </div>
                <Navigation links={this.state.links} isBurger={false} />
            </header>
        )
    }
}

export default Header
