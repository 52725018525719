import React, {Component} from 'react';

import './Footer.scss';

class Footer extends Component {
    render() {
        let currentYear = new Date()
        return (
            <footer className="container-fluid footer">
                <div className="container content">
                    <span className="col-12 tag">Developed BY <a href={this.props.savaLink}>Sava</a></span>
                    <span className="col-12 copy">2017 - {currentYear.getFullYear()}</span>
                </div>
            </footer>
        );
    }
}

export default Footer;