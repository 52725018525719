import React, {Component} from 'react'

import Tabs from './Tabs/Tabs'

import './TextImageSection.scss'

class TextImageSection extends Component {
    state = {
        indexContent: 0,
        schedule: this.props.schedule,
        futsalTabs: [
            {
                type: 'Info',
                title: 'Информация',
                classes: 'active',
                content: (
                    <div className="row info">
                        <div className="col-12 col-lg-6 text">
                            <ul>
                                <li>Мы играем в зале СК МГИМО</li>
                                <li>Прекрасный "мягкий" зал 40х20 метров</li>
                                <li>Формат 5 на 5 , турнир "в 3 команды</li>
                                <li>Мяч №4 с заниженным отскоком</li>
                                <li>Две группы по уровню игры</li>
                                <li>Манишки, электронное табло и турнирная таблица игрового дня</li>
                                <li>Интересный сезонный чемпионат для участников</li>
                                <li>Видеосъемка всех игр и фото участников</li>
                                <li>Отдельная раздевалка с душевыми</li>
                                <li>Организованное судейство матчей</li>
                                <li>Всегда дружеская атмосфера на играх</li>
                                <li>Дружим с дисциплиной</li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-6 image">
                            <img src={this.props.imageSrc} alt={this.props.imageAlt}/>
                        </div>
                    </div>
                )
            },
            {
                type: 'Schedule',
                title: 'Расписание',
                classes: '',
                content:
                    (
                    <div className="row">
                        <div className="col-12 col-lg-12 schedule">
                            <table className="desktop-table">
                                <tr>
                                    <td>Понедельник</td>
                                    <td className="empty">Вторник</td>
                                    <td>Среда</td>
                                    <td className="empty">Четверг</td>
                                    <td>Пятница </td>
                                    <td className="empty">Суббота</td>
                                    <td>Воскресенье</td>
                                </tr>
                                <tr>
                                    <td>
                                        <ul>
                                            <li> <i className="fa fa-2x fa-check"/> Есть игра</li>
                                            <li> <i className="fa fa-2x fa-clock-o"/> 20:00 - 22:00</li>
                                            <li> <i className="fa fa-2x fa-map-marker"/> Спортивный комлпекс МГИМО</li>
                                        </ul>
                                    </td>
                                    <td className="empty">Нет игр</td>
                                    <td>
                                        <ul>
                                            <li> <i className="fa fa-2x fa-check"/> Есть игра</li>
                                            <li> <i className="fa fa-2x fa-clock-o"/> 22:00 - 0:00</li>
                                            <li> <i className="fa fa-2x fa-map-marker"/> Спортивный комлпекс МГИМО</li>
                                        </ul>
                                    </td>
                                    <td className="empty">Нет игр</td>
                                    <td>
                                        <ul>
                                            <li> <i className="fa fa-2x fa-check"/> Есть игра</li>
                                            <li> <i className="fa fa-2x fa-clock-o"/> 20:00 - 22:00</li>
                                            <li> <i className="fa fa-2x fa-map-marker"/> Спортивный комлпекс МГИМО</li>
                                        </ul>
                                    </td>
                                    <td className="empty">Нет игр</td>
                                    <td>
                                        <ul>
                                            <li> <i className="fa fa-2x fa-check"/> Есть игра</li>
                                            <li> <i className="fa fa-2x fa-clock-o"/> 10:00 - 12:00</li>
                                            <li> <i className="fa fa-2x fa-map-marker"/> Спортивный комлпекс МГИМО</li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                            <table className="mobile-table">
                                <tr>
                                    <td>Понедельник</td>
                                    <td>
                                        <ul>
                                            <li> <i className="fa fa-check"/> Есть игра</li>
                                            <li> <i className="fa fa-clock-o"/> 20:00 - 22:00</li>
                                            <li> <i className="fa fa-map-marker"/> Спортивный комлпекс МГИМО</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="empty">Вторник</td>
                                    <td className="empty">Нет игр</td>
                                </tr>
                                <tr>
                                    <td>Среда</td>
                                    <td>
                                        <ul>
                                            <li> <i className="fa fa-check"/> Есть игра</li>
                                            <li> <i className="fa fa-clock-o"/> 22:00 - 0:00</li>
                                            <li> <i className="fa fa-map-marker"/> Спортивный комлпекс МГИМО</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="empty">Четверг</td>
                                    <td className="empty">Нет игр</td>
                                </tr>
                                <tr>
                                    <td>Пятница</td>
                                    <td>
                                        <ul>
                                            <li> <i className="fa fa-check"/> Есть игра</li>
                                            <li> <i className="fa fa-clock-o"/> 20:00 - 22:00</li>
                                            <li> <i className="fa fa-map-marker"/> Спортивный комлпекс МГИМО</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="empty">Суббота</td>
                                    <td className="empty">Нет игр</td>
                                </tr>
                                <tr>
                                    <td>Воскресенье</td>
                                    <td>
                                        <ul>
                                            <li> <i className="fa fa-check"/> Есть игра</li>
                                            <li> <i className="fa fa-clock-o"/> 10:00 - 12:00</li>
                                            <li> <i className="fa fa-map-marker"/> Спортивный комлпекс МГИМО</li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                )
            },
            {
                type: 'Map',
                title: 'Карта',
                classes: '',
                content: (
                    <div className="row map">
                        <div className="col-12 col-lg-12 text slide-in-left visible">
                            <iframe
                                src="https://yandex.ru/map-widget/v1/?um=constructor%3A63b2dbcaeb81d5c08944d9839c32ee0ef4f5b40782f3cd79f54bddcb609bb192&amp;source=constructor"
                                width="100%" height="450" frameBorder="0"/>
                        </div>
                    </div>
                )
            },
        ],
        footballTabs: [
            {
                type: 'Info',
                title: 'Информация',
                classes: 'active',
                content: (
                    <div className="row info">
                        <div className="col-12 col-lg-6 text">
                            <ul>
                                <li>Играем СК Лужники на травяной площадке 60 х 30 метров под открытым небом</li>
                                <li>Прекрасный газон с подогревом, раздевалки с душевыми</li>
                                <li>Формат 7на 7 мяч №5</li>
                                <li>Круглый год, разный уровень,  судейство, манишки, строго в "сороконожках"</li>
                                <li>Дружеская атмосфера и интереснная борьба</li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-6 image">
                            <img src={this.props.imageSrc} alt={this.props.imageAlt}/>
                        </div>
                    </div>
                )
            },
            {
                type: 'Schedule',
                title: 'Расписание',
                classes: '',
                content: (
                    <div className="row">
                        <div className="col-12 col-lg-12 schedule">
                            <table className="desktop-table">
                                <tr>
                                    <td className="empty">Понедельник</td>
                                    <td className="empty">Вторник</td>
                                    <td className="empty">Среда</td>
                                    <td className="empty">Четверг</td>
                                    <td className="empty">Пятница </td>
                                    <td className="empty">Суббота</td>
                                    <td>Воскресенье</td>
                                </tr>
                                <tr>
                                    <td className="empty">Нет игр</td>
                                    <td className="empty">Нет игр</td>
                                    <td className="empty">Нет игр</td>
                                    <td className="empty">Нет игр</td>
                                    <td className="empty">Нет игр</td>
                                    <td className="empty">Нет игр</td>
                                    <td>
                                        <ul>
                                            <li> <i className="fa fa-2x fa-check"/> Есть игра</li>
                                            <li> <i className="fa fa-2x fa-clock-o"/> 12:00 - 14:00</li>
                                            <li> <i className="fa fa-2x fa-map-marker"/> Спортивный комплекс Лужники, футбольное поле №14</li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                            <table className="mobile-table">
                                <tr>
                                    <td className="empty">Понедельник</td>
                                    <td className="empty">Нет игр</td>
                                </tr>
                                <tr>
                                    <td className="empty">Вторник</td>
                                    <td className="empty">Нет игр</td>
                                </tr>
                                <tr>
                                    <td className="empty">Среда</td>
                                    <td className="empty">Нет игр</td>
                                </tr>
                                <tr>
                                    <td className="empty">Четверг</td>
                                    <td className="empty">Нет игр</td>
                                </tr>
                                <tr>
                                    <td className="empty">Пятница</td>
                                    <td className="empty">Нет игр</td>
                                </tr>
                                <tr>
                                    <td className="empty">Суббота</td>
                                    <td className="empty">Нет игр</td>
                                </tr>
                                <tr>
                                    <td>Воскресенье</td>
                                    <td>
                                        <ul>
                                            <li> <i className="fa fa-check"/> Есть игра</li>
                                            <li> <i className="fa fa-clock-o"/> 12:00 - 14:00</li>
                                            <li> <i className="fa fa-map-marker"/> Спортивный комплекс Лужники, футбольное поле №14</li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                )
            },
            {
                type: 'Map',
                title: 'Карта',
                classes: '',
                content: (
                    <div className="row map">
                        <div className="col-12 col-lg-12 text slide-in-left visible">
                            <iframe
                                src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae8a820235930d747e77067c1f870ecef4ebae660176424de0e06925e60733dee&amp;source=constructor"
                                width="100%" height="450" frameBorder="0"></iframe>
                        </div>
                    </div>
                )
            },
        ],

    }

    render() {
        let sectionClasses = 'container-fluid text-image-section ' + this.props.type;
         return (
            <section className={sectionClasses} id={this.props.id}>
                <div className="dark"/>
                    <div className="content">
                        <div className="row">
                            <h2 className="col-12">{this.props.sectionTitle}</h2>
                        </div>
                        <Tabs tabs={this.props.type === 'floor'? this.state.futsalTabs: this.state.footballTabs} />
                    </div>
            </section>
         );
    }
}

export default TextImageSection