import React, { Component } from 'react'

import LeftMenu from '../LeftMenu/LeftMenu'

import './Navigation.scss'

class Navigation extends Component {
    state = {
        links: this.props.links.map((link, index) => {
            return (
                <li key={index}>
                    {' '}
                    <a href={link.href} rel="m_PageScroll2id">
                        {link.title}
                    </a>{' '}
                </li>
            )
        }),
        isVisibleMenu: false,
    }

    toggleLeftMenu = (event) => {
        if (this.state.isVisibleMenu)
            this.setState({
                isVisibleMenu: false,
            })
        else
            this.setState({
                isVisibleMenu: true,
            })
    }

    scrollToSection(href) {
        const id = href
        console.log(document.getElementById('football'))
        // document.querySelector(id).scrollIntoView({
        //     behavior: 'smooth',
        //     block: 'start'
        // });
    }

    render() {
        let burgerButtonClasses = ['d-block d-sm-block d-md-none col-2']
        let leftMenuVisible
        if (this.state.isVisibleMenu)
            leftMenuVisible = (
                <LeftMenu isVisible={true} links={this.state.links} />
            )

        if (this.state.isVisibleMenu)
            burgerButtonClasses.push('burger-button-active')
        else burgerButtonClasses.push('burger-button')

        return (
            <nav className="row">
                <ul className="d-none d-sm-none d-md-block col-12 normal-menu">
                    {this.state.links}
                </ul>
                <span href="#" className={burgerButtonClasses.join(' ')} onClick={this.toggleLeftMenu.bind(this)}>
                    <span></span>
                </span>
                {leftMenuVisible}
            </nav>
        )
    }
}

export default Navigation
