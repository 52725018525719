import React, {Component} from 'react';

import '../ Invitation/Invitation.scss';

import i1 from '../assets/img/i1 2.jpg';
import i2 from '../assets/img/i2.jpeg';
import i3 from '../assets/img/i3.jpg';

class Invitation extends Component {
    render() {
        return (
            <section className="invitation">
                <img className="col-4 firstImg" src={i1}></img>
                <img className="col-4 secondImg" src={i2}></img>
                <img className="col-4 thirdImg" src={i3}></img>
                <p className="col-12">{this.props.text}</p>
                <a href={this.props.link}>Записаться</a>
            </section>
        );
    }
}

export default Invitation;