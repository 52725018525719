import React, {Component} from 'react';

import './Principles.scss';

class Principles extends Component {
    state = {
        principles: [
            {title: 'Дружный спорт', description: 'Уважаем партнёров и соперников'},
            {title: 'Позитивный взгляд', description: 'С нами поддержка и улыбка'},
            {title: 'Честность в борьбе', description: 'Играем корректно и фаирплай'},
            {title: 'Командный подход', description: 'Один за всех и все за одного'}
        ]
    }
    render() {
        return (
            <section className="container-fluid principles" id={this.props.id}>
                <div className="content">
                            <ul className="row">
                                { this.state.principles.map((principle, index) => {
                                    return (
                                        <li className="a col-12 col-lg-6">
                                            <span className="digit">{index + 1}</span>
                                            <strong className="title">{principle.title}</strong>
                                            <span className="description">{principle.description}</span>
                                        </li>
                                    )
                                })}
                            </ul>

                </div>
            </section>
        );
    }
}

export default Principles;