import React, {Component} from 'react';
import './TextSection.scss';
import Tabs from "../TextImageSection/Tabs/Tabs";
import Principles from "../Principles/Principles";

class TextSection extends Component {
    state = {
        indexContent: 0,
        importantTabs: [
            {
                type: 'Principles',
                title: 'Принципы',
                classes: 'active',
                content: (
                    <Principles id="principles" />
                )
            },
            {
                type: 'Laws',
                title: 'Правила',
                classes: '',
                content: (
                    <div>
                        <p>Добровольная организация любителей спорта (футбол, волейбол, теннис). Организация создана на базах группы соцсети ВКонтакте https://vk.com/minifootballuzao и Телеграм-канала https://t.me/mini_football_moscow</p>
                        <p>Целью организации является создание условий для проведения спортивного досуга ее участниками .</p>
                        <h3>Общие правила для сообщества</h3>
                        <p>Сообщество представляет собой группу участников, принимающих и выполняющих правила организации.</p>
                        <p>
                            В сообществе существуют:
                            <ul>
                                <li>Администрация — ответственные члены сообщества, участвующие в создании организации и ее движении вперед;</li>
                                <li>Актив — активные члены сообщества, управляющие и  контролирующие проведение мероприятий;</li>
                                <li>Участники — ВСЕ МЫ.</li>
                            </ul>
                        </p>
                        <ol className="hlist">
                            <li>
                                <h4>Приветствуется в действиях участников:</h4>
                                <ol>
                                    <li>Активное участие в спортивных и иных мероприятиях, проводимых сообществом;</li>
                                    <li>Посильная помощь и содействие в организации мероприятий, как администрации, так и товарищам по организации;</li>
                                    <li>Предложения по улучшению организации, адресованные администрации группы;</li>
                                    <li>Фэйр-плэй в игровых моментах;</li>
                                    <li>Поддержание принципа равенства при распределении ресурсов между игроками и командами;</li>
                                    <li>Внимательное и бережное отношение к имуществу сообщества и его участников;</li>
                                    <li>Пропаганда здорового образа жизни (далее — ЗОЖ);</li>
                                    <li>Действия по принципам коллективизма и взаимовыручки;</li>
                                    <li>Корректная, красивая и результативная игра на мероприятиях группы;</li>
                                    <li>Отзывчивость в коммуникации внутри сообщества как в личке, так и в группах Телеграма.</li>
                                </ol>
                            </li>
                            <li>
                                <h4>ЗАПРЕЩЕНО:</h4>
                                <ol>
                                    <li>Неспортивное поведение до / во время / после мероприятий;</li>
                                    <li>Оскорбление любого участника(ов) группы или третьих(его) лиц(а) в любой форме;</li>
                                    <li>Жесткая и опасная игра;</li>
                                    <li>Сообщения и комментарии, пропагандирующие религиозные секты и общества;</li>
                                    <li>Разжигание расовой, национальной и религиозной ненависти;</li>
                                    <li>Умышленное и неумышленное провоцирование оппонентов на оскорбления и нецензурные выражения;</li>
                                    <li>Пропаганда, а также любое упоминание наркотических веществ, нетрадиционных межличностных отношений и других криминальных тем  вне зависимости от цели высказывания (порицание, шутка и проч.);</li>
                                    <li>Размещение информации коммерческого характера в личных целях;</li>
                                    <li>Размещение или упоминание информации по организации или проведению сторонних мероприятий, не согласованных с администрацией нашего сообщества;</li>
                                    <li>Размещение ссылок на вирусные, троянские и другие вредоносные программы.</li>
                                </ol>
                            </li>
                            <li>
                                <h4>Актив и Администрация.</h4>
                                <p>Актив и Администрация группы оставляют за собой следующие права:</p>
                                <ol>
                                    <li>Без предупреждения удалять любые сообщения и информацию, которые не соответствуют правилам группы;</li>
                                    <li>Без предупреждения закрывать доступ участникам в группу или на мероприятия группы;</li>
                                    <li>Корректировать состав и количество участников организации и групп по своему усмотрению при условии возмещения финансовой задолженности перед участником.</li>
                                </ol>
                            </li>
                            <li>
                                <h4>Ответственность.</h4>
                                <ol>
                                    <li>Актив и Администрация группы не несут ни гражданской, ни уголовной ответственности за информацию, опубликованную участниками сообщества, а также за возможный ущерб от этой информации. Вся информация публикуется исключительно для ознакомительного характера и не может быть использована в противозаконных целях;</li>
                                    <li>Администрация оставляет за собой право изменять данные правила группы по собственному усмотрению без предварительного уведомления участников группы, но с последующем оповещением об этом;</li>
                                    <li>Участие в группе подразумевает принятие и выполнение настоящих правил группы, поэтому любое нарушение этих правил будет считаться преднамеренным.</li>
                                </ol>
                                <p>Нарушение правил группы влечет за собой:</p>
                                <ol>
                                    <li>Предупреждение участника;</li>
                                    <li>Бан — блокировка доступа;</li>
                                    <li>Удаление участника из рядов сообщества без возможности восстановления.</li>
                                </ol>
                                <p>Срок бана устанавливается в зависимости от тяжести нарушений или их количества (на усмотрение администраторов).</p>
                                <p>Пользователь, получивший бан, по всем вопросам может обращаться к организатору https://t.me/Andrey_SSS ( Савин Андрей Юрьевич)</p>
                            </li>
                            <li>
                                <h4>Примечания.</h4>
                                <ol>
                                    <li>Всё то, что по каким-либо причинам не было указано в данных правилах, остается на усмотрение администрации группы.</li>
                                    <li>Если у Вас есть какие-то предложения или вопросы по содержанию правил нашей группы, направляйте их администрам групп.</li>
                                </ol>
                            </li>
                            <li>
                                <h4>Правила мероприятий и запись на них.</h4>
                                <ol>
                                    <li>Спортивные мероприятия и запись на них проходят согласно доступному расписанию;</li>
                                    <li>Расписание публикуется в анонсах записи на игры;</li>
                                    <li>Основа при записи —  внеочередное право на участие в мероприятии, т.е. ВСЕ участники при записи имеют равные права;</li>
                                    <li>Отсутствие ответа при записи и опросе может расцениваться как нарушение правил с соответствующим взысканием с участника. Понятие форс-мажор при записи и оплате отсутствует и не принимается к рассмотрению и апелляции;</li>
                                    <li>Участвовать в играх может только утвержденный при процессе записи состав.</li>
                                    <li>Он отображается в записи как ФИНАЛЬНЫЙ СПИСОК;</li>
                                    <li>Мероприятия, инвентарь и другие расходы в организации финансируются участниками сообщества на добровольных началах из собственных средств участников группы;</li>
                                    <li>Все оплаты как основы, так и разовых взносов после перечисления не возвращаются плательщикам вне зависимости от причин или сроков;</li>
                                </ol>
                                <p>Данные правила могут меняться без согласования с участниками сообщества. Об изменении правил своевременно происходит информирование участников СОЮЗа.</p>
                                <p>ЖЕЛАЕМ ВАМ УСПЕШНОЙ ИГРЫ)</p>
                            </li>
                        </ol>

                    </div>
                )
            },
            {
                type: 'Registration',
                title: 'Регистрация',
                classes: '',
                content: (
                    <div>
                        <p>Все желающие поучаствовать в играх, проводимых нашей группой, вступают в число участников союза.</p>
                        <p>Для этого они проходят регистрацию в телеграм боте по ссылке http://t.Me/UNION_Moscow_bot</p>
                        <p>После взаимодействия с ботом информация отправляется на модерацию и администрация исходя из загружености группы, текущих вакантных мест и других критериев принимает решение по одобрению кандидата. При положительном ответе , кандидат получает ответ в том же роботе знакомится с правилами и после проходит элементаный тест на знание правил. После этого кандидат становится полноправным участником и получает возможность выбрать интересные для него игровые дни . С помощью кнопок в боте УПРАВСОСТАВ он присоединяется к телеграмгруппам соответствующим выбранным игровым дням. В описаниях этих телеграм групп участник может познакомиться со временем записи на игру. Согласно расписания бот УПРАВСОСТАВ присылает сообщения которые в интуитивно понятной форме организуют процесс записи на игру и оплату игровых взносов.</p>
                    </div>
                )
            },
        ]
    }
    render() {
        let textContent;
        if (this.props.isTextCentered) textContent =  <div className="col-12" style={{textAlign: 'center'}}> {this.props.children} </div>
        else textContent = <div className="col-12" style={{textAlign: 'left'}}> {this.props.children} </div>
        return (
            <section className="container-fluid text-section" id={this.props.id}>
                <div className="row content">
                    <div className="row">
                        {this.props.sectionTitle !== ''? <h2 className="col-12">{this.props.sectionTitle}</h2>: null}
                    </div>
                    {this.props.isTabs? <Tabs typeTabs='red' tabs={this.state.importantTabs}/> : null}
                    {textContent}
                </div>
            </section>
        );
    }
}

export default TextSection;