import React, {Component} from 'react';

import './Tabs.scss'

class Tabs extends Component {
    state = {
        indexActivedTab: 0
    }

    changeContent = (index) => {
        this.setState({
            indexActivedTab: index
        })
    }

    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <ul className={this.props.typeTabs === 'red'? 'red-tabs': 'tabs'}>
                        { this.props.tabs.map( (tab, index) => {
                            return (
                                <li key={index} className={ (index===this.state.indexActivedTab) ? 'activeTab': ''} onClick={this.changeContent.bind(this, index)}>{tab.title}</li>
                            )
                        })}
                    </ul>
                </div>
                {this.props.tabs[this.state.indexActivedTab].content}
            </div>

        );
    }
}

export default Tabs;