import React, {Component} from 'react';

import './Icons.scss'

class Icons extends Component {
    render() {
        return (
            <div className="icons">
                    <a href={this.props.tLink}><i className="fa fa-telegram"/></a>
                    <a href={this.props.vLink}><i className="fa fa-vk"/></a>
                    <a href={this.props.wLink}><i className="fa fa-whatsapp"/></a>
            </div>
        );
    }
}

export default Icons;